import React from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';

import { Button } from '@xchange/uikit';
import FormField from './FormField';

interface DocumentCreatorForm {
  fields: DocumentCreatorForm[];
  onSubmit: (values: { [key: string]: any; }) => void;
}

const DocumentCreatorForm = ({
  fields,
  onSubmit
}) => {
  const { register, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {}
  });

  return (
    <StyledForm
      onSubmit={handleSubmit(onSubmit)}
      className="create-document-form"
    >
      {fields?.map((formfields, index) => (
        <div className="form_header_disclosures" key={index}>
          <h3>{formfields[0]['document_name']}</h3><hr></hr>
          {formfields.map((field) => (
            <div className="create-document-form__group" key={field.name}>
              <FormField
                field={field}
                defaultValue = {field.defaultValue ? field.defaultValue : null}
                error={Boolean(errors[field.name]?.message)}
                inputProps={{
                  ...register(field.name)
                }}
              />
            </div>
          ))}
             
        </div>  
      ))}
      <div className="create-document-form__group">
        <Button>Submit</Button>
      </div>
    </StyledForm>
  );
};

export default React.memo(DocumentCreatorForm);

const StyledForm = styled.form`
  .form_header_disclosures > h3{
    margin-bottom: 0;color:#df1721;
  }
  .create-document-form {
    &__group {
      padding: 5px 0;
    }
  }
  .button {
    min-width: 300px;
    margin: 16px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;